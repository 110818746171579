// IMPORT PICTURES
import Image1 from '../../Assets/Portfolio/image1.webp';
import Image2 from '../../Assets/Portfolio/image2.webp';
import Image3 from '../../Assets/Portfolio/image3.webp';
import Image4 from '../../Assets/Portfolio/image4.webp';
import Image5 from '../../Assets/Portfolio/logo.webp';
import Image6 from '../../Assets/Portfolio/image6.webp';
import Image7 from '../../Assets/Portfolio/image7.webp';
import Image8 from '../../Assets/Portfolio/image8.webp';
import Image9 from '../../Assets/Portfolio/image9.webp';

const Portfolio = [
  {
    title: 'Une parenthèse avec le chef',
    description:
      "Partagez un moment convivial et gastronomique en apprenant les secrets culinaires d'un chef passionné!",
    imageUrl: Image1,
    alt: "Partagez un moment convivial et gastronomique en apprenant les recettes secretes d'un chef passionné ! Par exemple : Asperges, caviar et pétales de fleurs.",
    url: 'my-site-5-clyon/product-page/une-parenthèse-exclusive-avec-le-chef',
  },
  {
    title: 'Visite des traboules ou du marché',
    description:
      'Découvrez les trésors culinaires lyonnais en flânant à travers un marché vibrant et coloré!',
    imageUrl: Image3,
    alt: "Panier issus du marché lyonnais composant les couleurs de l'arc en ciel avec des fruits, des légumes et des fleurs.",
    url: 'my-site-5-clyon/product-page/visite-des-traboules-ou-du-march%C3%A9',
  },
  {
    title: 'Cuisinons un repas lyonnais ensemble',
    description:
      'Découvrez les saveurs authentiques de Lyon en cuisinant un délicieux repas ensemble!',
    imageUrl: Image2,
    alt: 'Courges, carottes et pommes de terres en cuisson pour un repas convivial après un apprentissage dans la bonne humeur.',
    url: 'my-site-5-clyon/product-page/cuisinons-un-repas-lyonnais-ensemble',
  },
  {
    title: 'Tarte à la praline',
    description:
      'Découvrez tous les secrets pour réaliser une tarte à la praline aussi délicieuse que belle!',
    imageUrl: Image8,
    alt: 'Délicieuse brioche à la praline lyonnaise prête à cuire, réalisée par un apprenti cuisinier.',
    url: 'my-site-5-clyon/product-page/tarte-pralines-roses-dfe-lyon',
  },
  {
    title: '6 cours pour apprendre les bases',
    description:
      'Maîtrisez les fondamentaux de la cuisine en 6 cours captivants et enrichissants!',
    imageUrl: Image5,
    alt: 'Logo de Chef Lyon, école culinaire : 6 cours pour apprendre les bases',
    url: 'my-site-5-clyon/product-page/decoupe-et-preparations-debases',
  },
  {
    title: 'Cours de cuisine pour enfant ou ados',
    description:
      'Laissez les jeunes chefs exprimer leur créativité avec nos cours de cuisine conçus spécialement pour les enfants et les adolescents!',
    imageUrl: Image6,
    alt: 'Smoothie au kiwi avec sa chantilly, parfait pour les petits et les grands !',
    url: 'my-site-5-clyon/product-page/cours-cuisine-enfant-ou-ados',
  },
  {
    title: 'Atelier pâtisserie',
    description:
      'Laissez-vous emporter dans un monde sucré et créatif lors de notre atelier pâtisserie!',
    imageUrl: Image7,
    alt: "Pâte sablée et rouleau enfariné, le quotidien d'un élève patissier chez Chef Lyon",
    url: 'my-site-5-clyon/product-page/atelier-pâtisserie',
  },
  {
    title: 'Un plat et repas ensemble',
    description:
      'Partagez un plat et un repas ensemble, un moment de convivialité autour de la table, après ceux des fourneaux!',
    imageUrl: Image9,
    alt: "Assortiment d'accras de morues à partager.. ou non !",
    url: 'my-site-5-clyon/product-page/decoupe-de-volaille',
  },
  {
    title: 'Steak et galette vegetal',
    description:
      'Apprenez à préparer un savoureux steak végétal et une délicieuse galette vegan!',
    imageUrl: Image4,
    alt: 'Pizza revisitée par un chef avec tomates rôties, oignons et mozzarella.',
    url: 'my-site-5-clyon/product-page/steak-et-galette-vegetal',
  },
];

export default Portfolio;
