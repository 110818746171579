// PICTURES
import Client1 from '../../Assets/Biography/Commentaire/client1.webp';
import Client2 from '../../Assets/Biography/Commentaire/client2.webp';
import Client3 from '../../Assets/Biography/Commentaire/client3.webp';
import Client4 from '../../Assets/Biography/Commentaire/client4.webp';

// COMPOSANT
function Comment() {
  // RENDER
  return (
    <section className="comment">
      <h2 className="biographyTitle">LIVRE D'OR</h2>
      {/* FIRST COMMENT */}
      <div className="commentBox">
        <div className="commentPicture">
          <img
            src={Client1}
            alt={`Portrait de Marie, 21 ans, jeune brune souriante.`}
          />
        </div>

        <div className="commentText">
          <p className="text italic justify">
            " Il y a 5 mois Nous avons passé un superbe moment en cuisine lors
            de notre séminaire d'entreprise. Superbe recette, super dynamisme et
            de la bonne humeur, un chef au top! "
          </p>
          <p className="text italic"> Marie, 21 ans</p>
          <div className="backBox"></div>
        </div>
      </div>

      {/* SECOND COMMENT */}
      <div className="commentBox reverse">
        <div className="commentPicture">
          <img
            src={Client2}
            alt={`Portrait de Matthieu, Team Operateur au cheveux bruns.`}
          />
        </div>

        <div className="commentText">
          <p className="text italic justify">
            " Nous avons eu l’occasion de faire un atelier pour la réalisation
            de Raviolis avec le chef Thierry Rodolphe ! Nous ne pouvons que
            recommander , le chef nous a apporté sa connaissance et son
            savoir-faire. Nous avons ensuite dégusté nos réalisations avec lui
            dans son restaurant, dans un cadre intimiste. Superbe expérience ! "
          </p>
          <p className="text italic"> Matthieu, Team Operator</p>
          <div className="backBox"></div>
        </div>
      </div>

      {/* THIRD COMMENT */}
      <div className="commentBox">
        <div className="commentPicture">
          <img
            src={Client3}
            alt={`Portrait de Thaddeus, homme cinquantenaire avec des lunettes.`}
          />
        </div>

        <div className="commentText">
          <p className="text italic justify">
            "Une expérience culinaire exceptionnelle à Lyon avec le génie
            Thierry Rodolphe, organisée par l'agent de voyages Jan Schyer. Du
            marché local à la cuisine de Thierry, chaque moment a été une
            délectation de saveurs et d'apprentissage. Une recommandation forte
            pour cette expérience 5 étoiles, même pour ceux qui ne cuisinent pas
            souvent."
          </p>
          <p className="text italic">Thaddeus Bort, Seatle USA</p>
          <div className="backBox"></div>
        </div>
      </div>

      {/* QUARTZ COMMENT */}
      <div className="commentBox reverse">
        <div className="commentPicture">
          <img
            src={Client4}
            alt={`Portait de Vincent, homme blanc avec des lunettes de soleil.`}
          />
        </div>

        <div className="commentText">
          <p className="text italic justify">
            "Superbe expérience avec ce chef talentueux! Son cours de cuisine
            était instructif et amusant, créant une atmosphère conviviale. Nous
            avons apprécié non seulement apprendre à cuisiner, mais aussi
            déguster nos créations ensemble. Recommande vivement pour une
            aventure gastronomique mémorable !"
          </p>
          <p className="text italic"> Vincent, 36 ans</p>
          <div className="backBox"></div>
        </div>
      </div>
    </section>
  );
}

export default Comment;
