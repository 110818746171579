// DEPENDANCES
import React from 'react';
import ReactDOM from 'react-dom/client';

// ROUTER
import Router from './router';

// STYLE
import './css/style.css';

// RENDER
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
);
