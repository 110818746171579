// DEPENDANCES
import { useState } from 'react';

// DATAS
import PorfolioDatas from '../../Datas/Portfolio/portfolio';

// DELETED PICTURES
/* import Logo from '../../Assets/Portfolio/logo.jpeg'; */

// COMPOSANT
function Photos() {
  // IDENTIFY CARD HOVERED
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // RETURN
  return (
    <section className="photos">
      {PorfolioDatas.map((image, index) => (
        <div
          key={index}
          className="photoBox"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          {/* PICTURE CARD */}
          <div className="pictureBox">
            <a
              href={`/ShowroomLesson/${image.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* DESCRIPTION OR PICTURE */}
              {hoveredIndex === index ? (
                <div className="homeTitleBox">
                  <p>{image.title}</p>
                  <p className="minitext">{image.description}</p>
                </div>
              ) : (
                <img className="photo" src={image.imageUrl} alt={image.alt} />
              )}
            </a>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Photos;
