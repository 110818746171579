// DEPENDANCE
import { Link } from 'react-router-dom';

// COMPOSANT
function Planning() {
  // RENDER
  return (
    <div className="planningBox">
      <Link
        className="planningButton"
        aria-label="go to calendar"
        to={'/Calendar'}
      >
        DECOUVRIR NOTRE PLANNING MENSUEL
      </Link>
    </div>
  );
}

export default Planning;
