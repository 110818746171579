// COMPOSANT
function Map() {
  // RENDER
  return (
    <section className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11133.65237524648!2d4.8288042!3d45.7629109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4ebaaf03b4053%3A0x3c1c49b677dc7e0!2sChef%20Lyon!5e0!3m2!1sfr!2sfr!4v1699970070913!5m2!1sfr!2sfr"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="googleMap"
      ></iframe>
    </section>
  );
}

export default Map;
