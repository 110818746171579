// DEPENDANCES
import { Link } from 'react-router-dom';

// COMPOSANT
function Footer() {
  return (
    <footer>
      {/* CONTACT */}
      <aside className="contact">
        <h4 className="contactTitle">CHEF LYON</h4>
        <p className="contactText">16 quai Romain Rolland 69005 LYON</p>
        <p className="contactText">07 67 62 05 65</p>
        <a href="mailto:contact@cheflyon.fr" className="contactLink underline">
          contact@cheflyon.fr
        </a>
      </aside>

      {/* SOCIAL NETWORK */}
      <aside className="networks">
        <a
          href="https://www.facebook.com/cheflyon.thierryrodolphe/"
          target="_blank"
          rel="noreferrer"
          aria-label="go to facebook"
        >
          <i className="fa-brands fa-facebook networksIcon"></i>
        </a>
        <a
          href="https://www.instagram.com/chef_lyon/"
          target="_blank"
          rel="noreferrer"
          aria-label="go to instagram"
        >
          <i className="fa-brands fa-instagram networksIcon"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/cheflyon/"
          target="_blank"
          rel="noreferrer"
          aria-label="go to linkedin"
        >
          <i className="fa-brands fa-linkedin networksIcon"></i>
        </a>
        <a
          href="https://www.pinterest.fr/thierryrodolphe/"
          target="_blank"
          rel="noreferrer"
          aria-label="go to pinterest"
        >
          <i className="fa-brands fa-pinterest networksIcon"></i>
        </a>
      </aside>

      {/* NOTICES */}
      <aside className="notices">
        <Link className="noticeLink" to={'/Notice'}>
          MENTIONS LEGALES
        </Link>
        <Link className="noticeLink" to={'/CGV'}>
          CONDITIONS GENERALES DE VENTE
        </Link>
      </aside>
    </footer>
  );
}

export default Footer;
