export const Infos = [
  {
    title: 'Cooking School - Lyon',
    description:
      'Chef Lyon transforme les amateurs en chefs étoilés... ou au moins en experts des raviolis ! Venez découvrir que votre cuisine a bien plus de potentiel que de simples pâtes au beurre !',
  },
  {
    title: 'Team Building - Activité de groupe',
    description:
      "Envie de créer un véritable esprit d'équipe ? Oubliez les escape rooms ! Rien ne renforcera mieux vos liens que le défi de préparer un soufflé sans le faire retomber !",
  },
  {
    title: 'Teaching Kitchen - Cours culinaire',
    description:
      "Dans notre cuisine, nous croyons au potentiel de tout le monde, même ceux qui ont réussi à brûler de l'eau. Apprenez avec nous et repartez avec des astuces dignes de grands chefs.",
  },
  {
    title: 'Learn to Cook - Pour les petits et les grands',
    description:
      "Il n'y a pas d'âge pour apprendre de bonnes recettes ! Surprenez vos grands parents avec votre propre tarte à la Praline !",
  },
];
export default Infos;
