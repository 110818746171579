// DEPENDANCES
import { Link } from 'react-router-dom';

// COMPOSANT
function Erreur() {
  return (
    <main className="error">
      <h2>ERROR 404</h2>

      <p className="errorText">
        Je suis désolé mais cette recette n'existe pas.
      </p>

      <Link className="errorButton" aria-label="go to home" to={'/Home'}>
        <h3>Il est l'heure d'apprendre à cuisiner !!</h3>
      </Link>
    </main>
  );
}

export default Erreur;
