// COMPOSANT
function Job() {
  // RENDER
  return (
    <div className="jobBox">
      <a
        href="mailto:contact@cheflyon.fr"
        aria-label="send mail to Chef Lyon"
        className="jobButton"
      >
        Envie de rejoindre notre équipe ? <br /> Envoyez votre candidature !
      </a>
    </div>
  );
}

export default Job;
