// DATAS
import localPictures from '../../Datas/Shop/local';

// COMPONENTS
import Presentation from '../../Component/Presentation';
import Banner from '../../Component/Banner';
import ShopDetails from '../../Component/ShopDetails';
/* import Products from '../../Component/Products'; */

// COMPOSANT
function Shop() {
  // ANCRE UP
  window.scrollTo({
    top: 0,
  });

  // RENDER
  return (
    <div className="shop">
      <Presentation />
      <Banner />
      <ShopDetails />
      {/* PICTURES  */}
      <div className="shopPicture">
        {localPictures.map((picture) => (
          <div className="localPicture" key={picture.imageUrl}>
            <img src={picture.imageUrl} alt={picture.alt}></img>
            <p className="alt_image">{picture.alt}</p>
          </div>
        ))}
      </div>

      {/* <Products /> */}
    </div>
  );
}

export default Shop;
