// IMPORT PICTURES
import Local1 from '../../Assets/Shop/local1.webp';
import Local2 from '../../Assets/Shop/local2.webp';
import Local3 from '../../Assets/Shop/local3.webp';
import Local4 from '../../Assets/Shop/local4.webp';
import Local5 from '../../Assets/Shop/local5.webp';
import Local6 from '../../Assets/Shop/local6.webp';
import Local7 from '../../Assets/Shop/local7.webp';
import Local8 from '../../Assets/Shop/local8.webp';
import Local9 from '../../Assets/Shop/local9.webp';
import Local10 from '../../Assets/Shop/local10.webp';
import Local11 from '../../Assets/Shop/local11.webp';
import Local12 from '../../Assets/Shop/local12.webp';
import Local13 from '../../Assets/Shop/local13.webp';
import Local14 from '../../Assets/Shop/local14.webp';
import Local15 from '../../Assets/Shop/local15.webp';
import Local16 from '../../Assets/Shop/local16.webp';
import Local17 from '../../Assets/Shop/local17.webp';
import Local18 from '../../Assets/Shop/local18.webp';
import Local19 from '../../Assets/Shop/local19.webp';
import Local20 from '../../Assets/Shop/local20.webp';
import Local21 from '../../Assets/Shop/local21.webp';
import Local22 from '../../Assets/Shop/local22.webp';
import Local23 from '../../Assets/Shop/local23.webp';
import Local24 from '../../Assets/Shop/local24.webp';
import Local25 from '../../Assets/Shop/local25.webp';

const localPictures = [
  {
    imageUrl: Local1,
    alt: "Atelier de cuisine avec plan de travail et ustensils de cuisine prêt à l'emploi.",
  },
  {
    imageUrl: Local2,
    alt: "Pralines faites maisons pour les plus gourmands d'entre nous.",
  },
  {
    imageUrl: Local3,
    alt: 'Atelier culinaire lyonnais, parfait pour apprendre à cuisiner !',
  },
  {
    imageUrl: Local4,
    alt: 'Bocaux et ingrédients pour pâtisserie, riz, miel et farine.',
  },
  {
    imageUrl: Local5,
    alt: 'Décoration au sein des locaux, chaleureuse et cosy.',
  },
  {
    imageUrl: Local6,
    alt: 'Casseroles et ustensils de cuisine pour amateurs et professionnels, en inox.',
  },
  {
    imageUrl: Local7,
    alt: 'Tableau des recettes et plats proposés au sein des cours de cuisine.',
  },
  {
    imageUrl: Local8,
    alt: 'Récipient coloré rempli de gourmandises sucrées',
  },
  {
    imageUrl: Local9,
    alt: 'Chef utilisant une louche pour servir un bouillon de légumes.',
  },
  {
    imageUrl: Local10,
    alt: 'Préparation pour patisserie à base de beurre, sucre et farine.',
  },
  {
    imageUrl: Local11,
    alt: "Décoration au sein de l'atelier de cuisine Chef Lyon, un lieu convivial et accueillant.",
  },
  {
    imageUrl: Local12,
    alt: 'Arrière salle des ateliers de cuisine, parfait pour préparer de nombreux repas.',
  },
  {
    imageUrl: Local13,
    alt: "Dépose minutieuse d'un oeuf poché lors du dressage final d'un plat gastronomique par l'élève.",
  },
  {
    imageUrl: Local14,
    alt: 'Délicieux plat de viandes avec légumes et fleurs en décoration.',
  },
  {
    imageUrl: Local15,
    alt: "Exemple d'un dressage professionnel appris au sein des ateliers de cuisine Chef Lyon",
  },
  {
    imageUrl: Local16,
    alt: 'Apprentie cuisinière mettant en pratiques les conseils du chef.',
  },
  {
    imageUrl: Local17,
    alt: 'Préparation et derniers conseil du chef avant de placer les pâtés en croutes au four.',
  },
  {
    imageUrl: Local18,
    alt: 'Nombreux produits de qualités disposé sur le plan de travail, en attendant le début du cours.',
  },
  {
    imageUrl: Local19,
    alt: 'Quatres assiettes dréssés pour un repas ensemble, riche en saveur.',
  },
  {
    imageUrl: Local20,
    alt: "La confection d'un plat en équipe au sein d'un atelier culinaire.",
  },
  {
    imageUrl: Local21,
    alt: 'Dressage et apprentissage des techniques culinaires pour ce jeune apprenti.',
  },
  {
    imageUrl: Local22,
    alt: "Présentation des étapes de préparation d'un croissant français.",
  },
  {
    imageUrl: Local23,
    alt: 'Plateau de croissant prêt à mettre au four, avant de pouvoir passer à la dégustation !',
  },
  {
    imageUrl: Local24,
    alt: 'Délicieuse tarte au praline en préparation, avec sa pâte.',
  },
  {
    imageUrl: Local25,
    alt: "Groupe enthousiaste à l'idée de découvrir les secrets de la cuisine française avec Chef Lyon.",
  },
];

export default localPictures;
