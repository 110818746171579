// DEPENDANCES
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// COMPOSANT
function Cards(Card) {
  // CURRENT IMAGE SELECTED
  const [currentImage, setCurrentImage] = useState(0);
  // PICTURES DATA
  const images = [
    { url: Card.imageUrl, alt: Card.alt },
    { url: Card.imageUrl_2, alt: Card.alt_2 },
    { url: Card.imageUrl_3, alt: Card.alt_3 },
  ];

  // USER SWITCH IMAGE
  const handleImageChange = (index) => {
    setCurrentImage(index);
  };

  // RETURN
  return (
    // CARD
    <div className={`cardBox ${Card.title}`}>
      <Link className="cardLink" to={`/${Card.url}`}>
        {/* TITLE */}
        <h2 className="cardTitle">{Card.title}</h2>
        {/* PICTURE */}
        <div className="cardPicture">
          <img src={images[currentImage].url} alt={images[currentImage].alt} />
        </div>
      </Link>

      {/* NAVIGATION */}
      <div className="imageNavigation">
        {/* DOTS */}
        {images.map((_, index) => (
          <span
            key={index}
            className={`navDot ${currentImage === index ? 'active' : ''}`}
            onClick={() => handleImageChange(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default Cards;
