// DATA
import CardsPictures from '../../Datas/Home/cards';

// COMPONENTS
import Presentation from '../../Component/Presentation/index';
import Cards from '../../Component/Cards/index';
import Planning from '../../Component/Planning/index';
import Infos from '../../Component/Infos/index';
import Photos from '../../Component/Photos/index';
import Job from '../../Component/Job/index';
import Map from '../../Component/Map/index';

// COMPOSANT
function Home() {
  // ANCRE UP
  window.scrollTo({
    top: 0,
  });

  // RENDER
  return (
    <div id="homeBox">
      {/* PRESENTATION */}
      <Presentation />

      {/* NAVIGATION CARDS */}
      <main className="cards">
        {CardsPictures.map((card, index) => (
          <Cards
            key={index}
            title={card.title}
            url={card.url}
            url_2={card.url_2}
            url_3={card.url_3}
            imageUrl={card.imageUrl}
            imageUrl_2={card.imageUrl_2}
            imageUrl_3={card.imageUrl_3}
            alt={card.alt}
            alt_2={card.alt_2}
            alt_3={card.alt_3}
          />
        ))}
      </main>

      {/* PLANNING BUTTON */}
      <Planning />

      {/* INFORMATIONS */}
      <Infos />

      {/* PORTFOLIO */}
      <Photos />

      {/* JOB */}
      <Job />

      {/* MAP */}
      <Map />
    </div>
  );
}
export default Home;
