// DEPENDANCES
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// LAYOUT
import Header from './Layout/Header/index';
import Footer from './Layout/Footer/index';

// POP UP
/* import PopUp from './Component/PopUp/index'; */

// PAGES
import Home from './Pages/Home/index';
import Shop from './Pages/Shop/index';
import Lesson from './Pages/Lesson/index';
import ShowroomShop from './Pages/ShowroomShop/index';
import ShowroomLesson from './Pages/ShowroomLesson/index';
import ShowroomCalendar from './Pages/ShowroomCalendar/index';
import Biography from './Pages/Biography/index';
import Calendar from './Pages/Calendar/index';
import Portfolio from './Pages/Portfolio/index';
import Notice from './Pages/Notice/index';
import CGV from './Pages/CGV/index';
import Recipe from './Pages/Recipe/index';
import Error from './Pages/Error/index';

// Tracker
import PageTracker from './pageTracker';

//RENDER
function Router() {
  return (
    <BrowserRouter>
      <PageTracker />
      {/* <PopUp /> */}
      {/* HEADER */}
      <Header />
      <Routes>
        {/* HOME */}
        <Route path="/Home" element={<Home />} />
        {/* SHOP */}
        <Route path="/Shop" element={<Shop />} />
        {/* LESSON */}
        <Route path="/Lesson" element={<Lesson />} />
        {/* SHOWROOMS */}
        {/* SHOP */}
        <Route
          path="/ShowroomShop/:site/:category/:article"
          element={<ShowroomShop />}
        />
        {/* LESSON */}
        <Route
          path="/ShowroomLesson/:site/:category/:article"
          element={<ShowroomLesson />}
        />
        {/* CALENDAR */}
        <Route
          path="/ShowroomCalendar/:site/:category/:article"
          element={<ShowroomCalendar />}
        />
        {/* BIOGRAPHY */}
        <Route path="/Biography" element={<Biography />} />
        {/* CALENDAR */}
        <Route path="/Calendar" element={<Calendar />} />
        {/* PORTFOLIO */}
        <Route path="/Portfolio" element={<Portfolio />} />
        {/* NOTICE */}
        <Route path="/Notice" element={<Notice />} />
        {/* CGV */}
        <Route path="/CGV" element={<CGV />} />
        {/* RECIPE */}
        <Route path="/TartePraline" element={<Recipe />} />
        {/* ERROR */}
        <Route path="*" element={<Error />} />
        {/* REDIRECT TO HOME */}
        <Route path="" element={<Navigate to="/Home" />} />
      </Routes>
      {/* FOOTER */}
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
