// COMPOSANT
function Banner() {
  return (
    <div className="bannerBox">
      <div className="banner">
        <p className="bannerText">
          COURS DE CUISINE, RECETTES, LYON, CUISINER, CHEF, GASTRONOMIE,
          APPRENDRE, CULINAIRE, ATELIER CULINAIRE, PLATS, GOURMET, SAVEURS,
          ÉCOLE DE CUISINE, LEÇONS CULINAIRES, PRÉPARATION, CUISINE MAISON,
          TECHNIQUES, INGRÉDIENTS, MENUS, CUISINE DU MONDE, ART CULINAIRE,
          COOKING CLASS.
        </p>
      </div>
    </div>
  );
}

export default Banner;
