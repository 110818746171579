// DEPENDANCES
/* import { Link } from 'react-router-dom';
 */
// DATA
/* import AgendaDatas from '../../Datas/Calendar/fevrier';
 */
// IMAGE
import BannerPicture from '../../Assets/Calendar/calendar.webp';

// COMPOSANT
function Agenda() {
  let today = new Date(); // Day
  /* let currentMonth = today.getMonth(); // Month */
  let currentYear = today.getFullYear(); // Year

  // MONTH NAME
  /*   let months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  let monthName = months[currentMonth]; */

  // ANCRE UP
  window.scrollTo({
    top: 0,
  });

  // RENDER
  return (
    <main className="agenda">
      {/* BANNER */}
      <div className="calendarPicture">
        <h2 className="agendaTitle">{currentYear}</h2>
        <img
          src={BannerPicture}
          alt="Cuisine de Chef Lyon et vue sur la rue."
        ></img>
      </div>

      {/* CALENDAR */}
      <div className="allDays">
        <p>
          Pour obtenir les horaires ou toutes autres informations, merci de bien
          vouloir contacter notre équipe au 07 67 62 05 65 !
        </p>
      </div>
    </main>
  );
}

export default Agenda;
