// DEPENDANCES
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

// COMPONENT
import Banner from '../../Component/Banner/index';

// COMPOSANT
function ShowroomShop() {
  // VARIABLES
  let { site, category, article } = useParams();
  let iframeUrl = `https://t000005.wixsite.com/${site}/${category}/${article}`;

  // RENDER
  return (
    <main className="showroomShop">
      <div className="cheatCode">
        <Banner />
      </div>

      <div className="iframe-container">
        <iframe title="Wix Page" src={iframeUrl} width="100%" height="100%" />
      </div>

      <div className="cheatCode2">
        <Link className="returnButton" aria-label="return back" to="/Shop">
          RETOUR A LA BOUTIQUE
        </Link>
      </div>
    </main>
  );
}

export default ShowroomShop;
