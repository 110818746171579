function Conditions() {
  // ANCRE UP
  window.scrollTo({
    top: 0,
  });

  // RENDER
  return (
    <main className="conditions">
      <h2>Conditions générales de vente</h2>

      <h3>Préambule</h3>

      <p>
        Les présentes conditions générales de vente s’appliquent à toutes les
        ventes conclues sur le site Internet
        <a href="https://thierryrodolphe.com/">Chef Lyon</a> Le site Internet
        <a href="https://thierryrodolphe.com/">Chef Lyon</a> est un service de :
      </p>

      <p>
        - SASU CHEF LYON Capital social de 5000€ Numéro de TVA: FR38843334822
      </p>

      <p>- Située 56 RUE TRAMASSAC 69005 LYON</p>

      <p>
        - Adresse URL du site :
        <a href="https://thierryrodolphe.com/">Chef Lyon</a>
      </p>

      <p>- E-mail : contact@thierryrodolphe.com</p>

      <p>- Numéro de téléphone : 07 67 62 05 65</p>

      <p>
        - Le site Internet <a href="https://thierryrodolphe.com/">Chef Lyon</a>
        commercialise les produits suivants : Cours de cuisine pour particuliers
        & entreprises.
      </p>

      <p>
        Le client déclare avoir pris connaissance et avoir accepté les
        conditions générales de vente antérieurement à la passation de sa
        commande. La validation de la commande vaut donc acceptation des
        conditions générales de vente.
      </p>

      <h3>Article 1 – Principes</h3>

      <p>
        Les présentes conditions générales expriment l’intégralité des
        obligations des parties. En ce sens, l’acheteur est réputé les accepter
        sans réserve. Les présentes conditions générales de vente s’appliquent à
        l’exclusion de toutes autres conditions, et notamment celles applicables
        pour les ventes en magasin ou au moyen d’autres circuits de distribution
        et de commercialisation. Elles sont accessibles sur le site internet
        <a href="https://thierryrodolphe.com/">Chef Lyon</a> et prévaudront, le
        cas échéant, sur toute autre version ou tout autre document
        contradictoire. Le vendeur et l’acheteur conviennent que les présentes
        conditions générales régissent exclusivement leur relation. Le vendeur
        se réserve le droit de modifier ponctuellement ses conditions générales.
        Elles seront applicables dès leur mise en ligne. Si une condition de
        vente venait à faire défaut, elle serait considérée être régie par les
        usages en vigueur dans le secteur de la vente à distance dont les
        sociétés ont leur siège en France.
      </p>

      <p>
        Les présentes conditions générales de vente sont valables jusqu’au 13
        juin 2024.
      </p>

      <h3>Article 2 – Contenu</h3>

      <p>
        Les présentes conditions générales ont pour objet de définir les droits
        et obligations des parties dans le cadre de la vente en ligne de biens
        proposés par le vendeur à l’acheteur, à partir du site internet
        <a href="https://thierryrodolphe.com/">Chef Lyon</a>
      </p>

      <p>
        Les présentes conditions ne concernent que les achats effectués sur le
        site de <a href="https://thierryrodolphe.com/">Chef Lyon</a>.
      </p>

      <h3>Article 3 – Informations précontractuelles</h3>

      <p>
        L’acheteur reconnaît avoir eu communication, préalablement à la
        passation de sa commande et à la conclusion du contrat, d’une manière
        lisible et compréhensible, des présentes conditions générales de vente
        et de toutes les informations listées à l’article L. 221-5 du code de la
        consommation.
      </p>

      <p>
        Sont transmises à l’acheteur, de manière claire et compréhensible, les
        informations suivantes :
      </p>

      <p>- les caractéristiques essentielles du bien/service ;</p>

      <p>- le prix du bien et/ou le mode de calcul du prix ;</p>

      <p>
        - s’il y a lieu, tous les frais supplémentaires de transport, de
        livraison ou d’affranchissement et tous les autres frais éventuels
        exigibles ;
      </p>

      <p>
        - en l’absence d’exécution immédiate du contrat, la date ou le délai
        auquel le vendeur s’engage à livrer le bien, quel que soit son prix ;
      </p>

      <p>
        - les informations relatives à l’identité du vendeur, à ses coordonnées
        postales, téléphoniques et électroniques, et à ses activités, celles
        relatives aux garanties légales, aux fonctionnalités du contenu
        numérique et, le cas échéant, à son interopérabilité, à l’existence et
        aux modalités de mise en oeuvre des garanties et autres conditions
        contractuelles.
      </p>

      <h3>Article 4 – La commande</h3>

      <p>
        Pour les cours en comité privé plusieurs activités simultanées pourront
        être mise en œuvre pour l’ensemble des groupes.
      </p>

      <p>
        Les menus sont définis par apport à la saison, des produits du moment et
        a l’appréciation du chef dispensant le cours suivant l’intitulé du cours
        sélectionnés.
      </p>

      <p>
        Des variantes pourront être apporté à la recette de base si le chef le
        décide. La recette de base pourra être adaptée si un des participants ne
        peux en consommer ou manipuler un des produits secondaires de la
        recette.
      </p>

      <p>
        Les cours collectifs seront modulables mais leurs contenus resteront
        celui du cahier des charges défini par l’intitulé du cours. Si aucun
        menu n’est défini à la réservation des cours la proposition du chef fera
        choix.
      </p>

      <p>
        Les cours en comité privé, quand à eux pourront être entièrement modulés
        à la demande du client au moment de la réservation.
      </p>

      <p>
        Certain produits ou certaines boissons ou techniques demanderont un
        changement de prix part rapport au tarif de base, celui ci sera défini
        sur le devis.
      </p>

      <p>
        Les plats fabriqués pendant les cours restent la propriété de l’atelier
        et seront consommés collectivement a la fin de celui ci.
      </p>

      <p>
        A l’exception des cours de pâtisseries et de cours sans suivis de repas
        les participants repartent avec leurs créations.
      </p>

      <p>Les cours enfants et ados repartent avec leurs créations.</p>

      <p>
        La chaine du froid ou du chaud des plats emportés dans ce cas sera prise
        en charge par les participants eux mêmes ou les tuteurs de ceux ci à la
        sortie de la cuisine atelier.
      </p>

      <p>
        Bien que les boissons soient à discrétion pendant le cours, il pourra y
        avoir retrait pour l’ensemble du groupe des boissons alcoolisées, si le
        chef de cuisine estime une dérive pouvant nuire au bon déroulement et à
        la sécurité du cours. Toute personne présentant des comportements à
        risques pour elle ou pour la cohésion du groupe pourra en être exclue
        sans remboursement.
      </p>

      <p>
        Pour pouvoir organiser l’atelier, vous devez être un minimum de 5
        personnes et un maximum de 12 personnes. Un acompte de 20% sera demandé
        pour la réservation des cours.
      </p>

      <p>
        Le paiement doit impérativement être fait pour tout le groupe deux
        semaines avant la date de l’atelier. Aucun remboursement ne pourra avoir
        lieu si un participant se désiste à la semaine précédant le cours. En
        cas d’annulation l’acompte ne sera pas restitué si l’annulation ce fait
        dans les 7 jours précédant le cours.
      </p>

      <h3>Article 5 – Signature électronique</h3>

      <p>
        La fourniture en ligne du numéro de carte bancaire de l’acheteur et la
        validation finale de la commande vaudront preuve de l’accord de
        l’acheteur :
      </p>

      <p>- exigibilité des sommes dues au titre du bon de commande ;</p>

      <p>
        – signature et acception expresse de toutes les opérations effectuées.
      </p>

      <h3>Article 6 – Confirmation de commande</h3>

      <p>
        Le vendeur fournit à l’acheteur une confirmation de commande, par
        messagerie électronique.
      </p>

      <h3>Article 7 – Preuve de la transaction</h3>

      <p>
        Les registres informatisés, conservés dans les systèmes informatiques du
        vendeur dans des conditions raisonnables de sécurité, seront considérés
        comme les preuves des communications, des commandes et des paiements
        intervenus entre les parties. L’archivage des bons de commande et des
        factures est effectué sur un support fiable et durable pouvant être
        produit à titre de preuve.
      </p>

      <h3>Article 8 – Informations sur les produits</h3>

      <p>
        Les produits régis par les présentes conditions générales sont ceux qui
        figurent sur le site internet du vendeur et qui sont indiqués comme
        vendus par le vendeur. Ils sont proposés dans la limite des stocks
        disponibles. Les produits sont décrits et présentés avec la plus grande
        exactitude possible. Toutefois, si des erreurs ou omissions ont pu se
        produire quant à cette présentation, la responsabilité du vendeur ne
        pourrait être engagée.
      </p>

      <p>Les photographies des produits ne sont pas contractuelles.</p>

      <h3>Article 9 – Prix</h3>

      <p>
        Le vendeur se réserve le droit de modifier ses prix à tout moment mais
        s’engage à appliquer les tarifs en vigueur indiqués au moment de la
        commande, sous réserve de disponibilité à cette date.
      </p>

      <p>
        Les prix sont indiqués en euros. Ils ne tiennent pas compte des frais de
        livraison, facturés en supplément, et indiqués avant la validation de la
        commande. Les prix tiennent compte de la TVA applicable au jour de la
        commande et tout changement du taux applicable TVA sera automatiquement
        répercuté sur le prix des produits de la boutique en ligne.
      </p>

      <p>
        Si une ou plusieurs taxes ou contributions, notamment environnementales,
        venaient à être créées ou modifiées, en hausse comme en baisse, ce
        changement pourra être répercuté sur le prix de vente des produits.
      </p>

      <h3>Article 10 – Mode de paiement</h3>

      <p>
        Il s’agit d’une commande avec obligation de paiement, ce qui signifie
        que la passation de la commande implique un règlement de l’acheteur.
      </p>

      <p>
        Pour régler sa commande, l’acheteur dispose, à son choix, de l’ensemble
        des modes de paiement mis à sa disposition par le vendeur et listés sur
        le site du vendeur. L’acheteur garantit au vendeur qu’il dispose des
        autorisations éventuellement nécessaires pour utiliser le mode de
        paiement choisi par lui, lors de la validation du bon de commande. Le
        vendeur se réserve le droit de suspendre toute gestion de commande et
        toute livraison en cas de refus d’autorisation de paiement par carte
        bancaire de la part des organismes officiellement accrédités ou en cas
        de non-paiement. Le vendeur se réserve notamment le droit de refuser
        d’effectuer une livraison ou d’honorer une commande émanant d’un
        acheteur qui n’aurait pas réglé totalement ou partiellement une commande
        précédente ou avec lequel un litige de paiement serait en cours
        d’administration. Le paiement du prix s’effectue en totalité au jour de
        la commande, selon la modalité suivante :
      </p>

      <p>– carte bancaire</p>

      <p>
        Le cas échéant, un acompte calculé selon les modalités suivantes : 30%
        de la somme totale, pourra être exigé lors de la passation de la
        commande par l’acheteur, acompte payable en totalité et en un seul
        versement, selon les modalités de règlement prévues. Hors cas de force
        majeure, toute annulation de la commande par l’acheteur à compter de 8
        jours après la validation de la commande ne pourra donner lieu au
        remboursement de cet acompte prévu.
      </p>
    </main>
  );
}

export default Conditions;
