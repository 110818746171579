// DEPENDANCES
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

// COMPOSANT
function Presentation() {
  // VARIABLES
  let location = useLocation(); // URL

  // BACKGROUND & TEXT COLORS
  let [BackColor, setBackColor] = useState('/Home'); // Background color

  // COLORS DEPENDS ON URL
  useEffect(() => {
    if (location.pathname === '/Shop') {
      // SHOP COLORS PATTERN
      setBackColor('ShopHeaderColors');
    } else {
      // DEFAULT COLORS PATTERN
      setBackColor('HomeHeaderColors');
    }
  }, [location.pathname]);

  // RENDER
  return (
    <section className={`presentation ${BackColor}`}>
      <div className="presentationBox">
        <Link to={'/Biography'}>
          <h2 className="presentationTitle">
            CHEF LYON <br /> ATELIER CULINAIRE
          </h2>
        </Link>

        <div>
          <p>
            Cours de cuisine pour passionés et pour imposteurs qui se la
            racontent.
          </p>

          <p className="italic black">
            Cooking class with a local professionnal chef.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Presentation;
