// IMAGES
import Biography_1 from '../../Assets/Biography/Chef/thierry.webp';
import Biography_2 from '../../Assets/Biography/Chef/cook.webp';

// COMPONENT
import Comment from '../../Component/Comment/index';

// COMPOSANT
function Biography() {
  // ANCRE UP
  window.scrollTo({
    top: 0,
  });

  return (
    <section className="biography">
      {/* BIOGRAPHY */}
      <main>
        <section className="paragraphe_1">
          <div className="paragrapheText">
            <div className="paragrapheTitle">
              <h2 className="biographyTitle">THIERRY-RODOLPHE </h2>
              <p>Chef cuisinier</p>
            </div>

            <article>
              <p className="biographyText justify">
                Ayant travaillé dans les plus beaux établissements en France
                comme à l’international, il met son talent de Chef, au service
                de nombreux Chefs de renom, pour apporter son innovation et la
                rigueur dans la mise en production des process de restauration.
                Véritable Coach de dirigeants de la gastronomie française, il
                apporte sa compétence à de nombreux entrepreneurs développant de
                nouvelles activités ou concept en région lyonnaise, afin
                d’apporter un conseil stratégique précieux de professionnel, une
                connaissance du sourcing et une maitrise incontestée des ratios
                et autres procédures qui assurent régularité des recettes et
                rentabilité des affaires. C’est un partenaire rare pour les
                entrepreneurs qui souhaitent se lancer dans une aventure
                culinaire, un conseil précieux qui capitalise son expertise et
                son expérience. N’hésitez pas à lui demander conseil !
              </p>
            </article>
          </div>

          <div className="paragrapheImage">
            <img
              src={`${Biography_1}`}
              alt="Thierry Rodolphe, chef cuisiner, jonglant avec des légumes."
            ></img>
          </div>
        </section>

        <section className="paragraphe_2">
          <div className="paragrapheText">
            <article>
              <p className="biographyText justify">
                Chef Lyon vous offre une gamme complète de services culinaires
                sur mesure pour le secteur professionnel. Son expertise inclut
                la création ou la réadaptation de recettes, la conception de
                plans de formation personnalisés pour vos équipes, un coaching
                dédié au lancement de projets culinaires, ainsi que la sélection
                et la mise en relation avec les fournisseurs les plus adaptés.
                Avec son talent inégalé, Thierry Rodolphe évalue vos projets et
                développe des solutions sur mesure pour les concrétiser. De
                plus, il excelle dans la réalisation de prototypes culinaires
                pour vos cartes ou portfolios de produits, la conception
                d'animations thématiques pour des présentations de produits
                mémorables, et la rédaction d'articles culinaires pour
                promouvoir votre entreprise sur les réseaux sociaux. Faites
                appel à Chef Lyon pour une expertise culinaire incomparable.
              </p>
            </article>
          </div>

          <div className="paragrapheImage">
            <img
              src={`${Biography_2}`}
              alt="Thierry Rodolphe, chef cuisiner, en train de cuisiner."
            ></img>
          </div>
        </section>
      </main>

      {/* COMMENT */}
      <Comment />
    </section>
  );
}

export default Biography;
