// DEPENDANCES
import React, { useState } from 'react';

// DATAS
import InfosDatas from '../../Datas/Home/infos';

// DELETED PICTURES
/* import InfoPictureLeft from '../../Assets/Home/Infos/left.webp';
import InfoPictureCenter from '../../Assets/Home/Infos/center.webp';
import InfoPictureRight from '../../Assets/Home/Infos/right.webp'; */

// COMPOSANT
function Infos() {
  const [isOpen, setIsOpen] = useState(null);

  // SWITCH INFORMATION DESCRIPTION
  function switchDescription(index) {
    if (isOpen === index) {
      setIsOpen(null);
    } else {
      setIsOpen(index);
    }
  }

  // RENDER
  return (
    <section className="infos">
      {/* PICTURES */}
      {/* <div className="picturesBox">
        <div className="Picture">
          <img
            src={InfoPictureLeft}
            alt={`Pancake japonnais avec une chantilly au miel et un coulis de butternut.`}
          />
        </div>

        <div className="Picture">
          <img
            src={InfoPictureCenter}
            alt={`Mousse de fromage de chèvre avec coulis au vin rouge et baies de grenade.`}
          />
        </div>

        <div className="Picture">
          <img
            src={InfoPictureRight}
            alt={`Caviar d'aubergines accompagné de sa polenta.`}
          />
        </div>
      </div> */}

      {/* INFORMATIONS */}
      <h2 className="infoTitles">COOKING CLASS</h2>
      <div className="infosBox">
        {InfosDatas.map((info, index) => (
          <button
            className="infoButton"
            aria-label="open/close informations"
            onClick={() => switchDescription(index)}
            key={index}
          >
            <div
              className={`infoHeader ${
                isOpen === index ? 'openInfo' : 'closedInfo'
              }`}
            >
              <h3 className="infoTitle underline">{info.title}</h3>
              <i
                className={`fa-solid  ${
                  isOpen === index ? 'fa-arrow-up' : 'fa-arrow-down'
                }`}
              ></i>
            </div>

            {isOpen === index && (
              <p className="infoText italic justify">{info.description}</p>
            )}
          </button>
        ))}
      </div>
    </section>
  );
}
export default Infos;
