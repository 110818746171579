// IMPORT PICTURES
import Cook from '../../Assets/Home/Cards/cook_3.webp';
import Chef from '../../Assets/Home/Cards/chef.webp';
import Shop from '../../Assets/Home/Cards/shop_1.webp';
import Cook_2 from '../../Assets/Home/Cards/cook_2.webp';
import Chef_2 from '../../Assets/Home/Cards/chef_2.webp';
import Shop_2 from '../../Assets/Home/Cards/shop_2.webp';
import Cook_3 from '../../Assets/Home/Cards/cook.webp';
import Chef_3 from '../../Assets/Home/Cards/chef_3.webp';
import Shop_3 from '../../Assets/Home/Cards/shop_3.webp';

export const Cards = [
  {
    title: 'COOKING ACTIVITY',
    url: 'Lesson',
    imageUrl: Cook,
    imageUrl_2: Cook_2,
    imageUrl_3: Cook_3,
    alt: "Le travail minutieux d'un apprenti cuisinier concentré sur le dressage d'un plat gastronimique.",
    alt_2: "Les conseils d'un chef cuisinier pédagogue et passioné",
    alt_3:
      'Apprenez à patisser la pâte comme un chef au sein des ateliers de cuisine proposés par Chef Lyon.',
  },
  {
    title: 'LE CHEF',
    url: 'Biography',
    imageUrl: Chef,
    imageUrl_2: Chef_2,
    imageUrl_3: Chef_3,
    alt: 'Chef Thierry Rodolphe, professeur culinaire et fin gourmet.',
    alt_2:
      "Présentation du dressage étoilée d'un plat de gastronomie française.",
    alt_3:
      "Dressage d'une assiette gourmande selon le savoir-faire gastronomique français.",
  },
  {
    title: 'COOKING WORKSHOP',
    url: 'Shop',
    imageUrl: Shop,
    imageUrl_2: Shop_2,
    imageUrl_3: Shop_3,
    alt: 'Venez retrouvez tous nos ingrédients et produits locaux au sein de notre atelier de cuisine lyonnais.',
    alt_2:
      'Etagère proposant de nombreux choix de produits alimentaires et ustensils de cuisine.',
    alt_3:
      "Ustensils de cuisines et couteaux de cuisine prêts à l'emploi, sur plan de travail au sein d'un atelier culinaire.",
  },
];
export default Cards;
