// DEPENDANCES
import React, { useState } from 'react';

//DATAS
import coursParticuliersDatas from '../../Datas/Lesson/coursParticuliers';
import coursGroupeDatas from '../../Datas/Lesson/coursGroupe';

// COMPONENTS
import LessonCard from '../../Component/LessonCard/index';

//COMPOSANT
function Cours() {
  let [selectLesson, setSelectedLesson] = useState('particulier'); // Select lesson type

  // SWITCH TO PARTICULAR
  function switchToParticulier() {
    setSelectedLesson('particulier');
  }

  // SWITCH TO GROUP
  function switchToGroup() {
    setSelectedLesson('group');
  }

  // ANCRE UP
  window.scrollTo({
    top: 0,
  });

  // RENDER
  return (
    <div className="lessonBox">
      {/* MARGIN */}
      <section className="margin">
        <div className="buttonsBox">
          <button
            className="switchLessonButton"
            aria-label="switch to cours particulier"
            onClick={switchToParticulier}
          >
            Cours Particulier
          </button>
          <button
            className="switchLessonButton"
            aria-label="switch to cours d'entreprise"
            onClick={switchToGroup}
          >
            Cours Entreprise
          </button>
        </div>

        <article className="descriptionBox">
          <h2 className="marginTitle italic">
            CUISINE POUR PASSIONNES ET IMPOSTEURS QUI SE LA RACONTENT
          </h2>
          <p className="marginText justify">
            Vous bénéficierez avec Thierry Rodolphe et son équipe, des tours de
            mains de pros et vous decouvrirez les petits secrets de cuisiniers.
            Chef Lyon vous propose des cours de cuisine ou de pâtisserie, pour
            vous apprendre à faire des recettes locales, des plats traditionnels
            de la gastronomie française et des recettes actuelles et créatives.
            Après le cours pratique en cuisine où vous aurez mis la main à la
            pâte pour préparer un, deux ou trois plats (option végétarienne
            disponible), nous partagerons ce repas préparé ensemble, accompagné
            de vins locaux. Aucune expérience de cuisine n’est demandée pour
            participer à ces classes conçues pour les amateurs de gastronomie.
            Vous l’aurez compris, ces cours sont des occasions magiques de créer
            du lien avec ceux que vous aimez, tout en apprenant sérieusement le
            savoir-faire de chefs, sans se prendre trop au sérieux.
          </p>
        </article>
      </section>

      <div className="decorationLine"></div>

      {/* LESSONS */}
      <main className="lessons">
        {selectLesson === 'particulier' &&
          coursParticuliersDatas.map((cours, index) => (
            <LessonCard key={index} {...cours} />
          ))}
        {selectLesson === 'group' &&
          coursGroupeDatas.map((cours, index) => (
            <LessonCard key={index} {...cours} />
          ))}
      </main>
    </div>
  );
}

export default Cours;
