// DEPENDANCES
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

// IMAGE
import NavigationPicture from '../../Assets/Navigation/navigationPicture.avif';

// SOUND
import CoqSound from '../../Assets/Coq.mp3';

// COMPOSANT
function Header() {
  // VARIABLES
  let location = useLocation(); // URL

  // LEFT CORNER COLORS
  let [leftBackColor, setLeftBackColor] = useState('/Home'); // Left corner color
  let [leftColor, setLeftColor] = useState('/Home'); // Left text color

  // RIGHT CORNER COLORS
  let [rightBackColor, setRightBackColor] = useState('/Home'); // Right corner color
  let [rightColor, setRightColor] = useState('/Home'); // Right text color

  // NAVIGATION ON/OFF
  let [isOpen, setIsOpen] = useState(false); // Navigation On/Off
  let navigationState = isOpen ? 'fall' : 'up'; //
  let fixedButton = isOpen ? 'fixedButton' : '';

  // HEADER COLORS DEPENDS ON URL
  useEffect(() => {
    // HOME COLORS PATTERN
    if (location.pathname === '/Home') {
      // LEFT
      setLeftBackColor('HomeHeaderColors');
      setLeftColor('HomeHeaderColors');
      // RIGHT
      setRightBackColor('HomeHeaderColors');
      setRightColor('HomeHeaderColors');
      // SHOP COLORS PATTERN
    } else if (location.pathname === '/Shop') {
      // LEFT
      setLeftBackColor('ShopHeaderColors');
      setLeftColor('ShopHeaderColors');
      // RIGHT
      setRightBackColor('ShopHeaderColors');
      setRightColor('ShopHeaderColors');
      // LESSON COLORS PATTERN
    } else if (location.pathname === '/Lesson') {
      // LEFT
      setLeftBackColor('LessonHeaderColorsLeft');
      setLeftColor('LessonHeaderColorsLeft');
      // RIGHT
      setRightBackColor('LessonHeaderColorsRight');
      setRightColor('LessonHeaderColorsRight');
      // BIOGRAPHY COLORS PATTERN
    } else if (location.pathname === '/Biography') {
      // LEFT
      setLeftBackColor('BiographyHeaderColors');
      setLeftColor('BiographyHeaderColors');
      // RIGHT
      setRightBackColor('BiographyHeaderColors');
      setRightColor('BiographyHeaderColors');
      // PORTFOLION COLORS PATTERN
    } else if (location.pathname === '/Portfolio') {
      // LEFT
      setLeftBackColor('PortfolioHeaderColors');
      setLeftColor('PortfolioHeaderColors');
      // RIGHT
      setRightBackColor('PortfolioHeaderColors');
      setRightColor('PortfolioHeaderColors');
      // DEFAULT COLORS PATTERN
    } else {
      // LEFT
      setLeftBackColor('defaultHeaderColors');
      setLeftColor('defaultHeaderColors');
      // RIGHT
      setRightBackColor('defaultHeaderColors');
      setRightColor('defaultHeaderColors');
    }
  }, [location.pathname]);

  // NAVIGATION ON/OFF
  function SwitchNav() {
    setIsOpen(!isOpen);
  }

  // COCORICO SOUND ON BIO CLICK
  function playSound() {
    const audio = new Audio(CoqSound);
    audio.play();
  }

  //RENDER
  return (
    <header>
      {/* LEFT CORNER TITLE */}
      <div className={`leftBox ${leftBackColor}`}>
        <Link className="homeLink" aria-label="go to home" to={'/Home'}>
          <div className={`homeButton ${leftColor}`}>
            <i className={`fa-solid fa-house`}></i>
          </div>
        </Link>
      </div>

      {/* RIGHT CORNER BUTTON */}
      <div className={`rightBox ${rightBackColor}`}>
        <button
          className={`navigationButton ${fixedButton}`}
          onClick={SwitchNav}
          aria-label="navigation menu"
        >
          {!isOpen && <i className={`fa-solid fa-bars ${rightColor}`}></i>}
          {isOpen && <i className={`fa-solid fa-x noColor`}></i>}
        </button>
      </div>

      {/* NAVIGATION MENU */}
      <nav className={navigationState}>
        <ul className="navigationList">
          <li className="navigationElement">
            <Link className="navigationLink" to="/Home" onClick={SwitchNav}>
              Accueil
            </Link>
          </li>
          <li className="navigationElement">
            <Link className="navigationLink" to="/Lesson" onClick={SwitchNav}>
              Cours
            </Link>
          </li>
          <li className="navigationElement">
            <Link className="navigationLink" to="/Shop" onClick={SwitchNav}>
              Shop
            </Link>
          </li>
          <li className="navigationElement">
            <Link className="navigationLink" to="/Calendar" onClick={SwitchNav}>
              Agenda
            </Link>
          </li>
          <li className="navigationElement">
            <Link
              className="navigationLink"
              id="bio"
              to="/Biography"
              onClick={() => {
                playSound();
                SwitchNav();
              }}
            >
              Bio
            </Link>
          </li>
          <li className="navigationElement">
            <Link
              className="navigationLink"
              to="/Portfolio"
              onClick={SwitchNav}
            >
              Portfolio
            </Link>
          </li>
        </ul>

        <div className="decorationLine"></div>

        <div className="navigationPicture">
          <img
            src={NavigationPicture}
            alt={
              "Logo de 'Chef Lyon', pôle culinaire lyonnais situé dans le 5ème arrondissement."
            }
          />
        </div>
      </nav>
    </header>
  );
}
export default Header;
