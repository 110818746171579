// COMPOSANT
function ShopDetails() {
  return (
    <section className="shopDetails">
      <h3 className="detailsTitle">NOTRE ATELIER</h3>
      <p className="shopText justify">
        Bienvenue dans notre boutique, le souvenir de votre cours de cuisine
        avec nous. Après avoir perfectionné vos talents culinaires, vous pouvez
        maintenant prolonger l'expérience chez vous. Découvrez une sélection
        minutieusement choisie de produits de qualité, et d'ustensiles de chef
        rares. Chaque article que nous proposons vous aidera à transformer votre
        cuisine en un véritable laboratoire gastronomique. Vous n'avez plus qu'à
        prendre les saveurs et les techniques que vous avez apprises dans nos
        cours et les ramener à la maison pour créer des plats dignes d'un chef.
        Nous sommes fiers de vous offrir tout ce dont vous avez besoin pour
        briller en cuisine, et notre boutique est le complément parfait à votre
        voyage culinaire.
      </p>
    </section>
  );
}

export default ShopDetails;
