// COMPONENT
import Banner from '../../Component/Banner/index';

// COMPOSANT
function Portfolio() {
  // ANCRE UP
  window.scrollTo({
    top: 0,
  });

  //RENDER
  return (
    <main className="portfolio">
      {/* BANNER */}

      <Banner />

      {/* IFRAME */}
      <iframe
        title="Wix Page"
        src="https://t000005.wixsite.com/my-site-5-clyon/portf"
        width="100%"
        height="100%"
      />
    </main>
  );
}

export default Portfolio;
