// DEPENDANCES
import { useState } from 'react';
import { Link } from 'react-router-dom';

// COMPOSANT
function LessonCard(data) {
  let [language, setLanguage] = useState(false); // Select language

  // SWITCH TO FRENCH
  function switchToFrench() {
    setLanguage(false);
  }

  // SWITCH TO ENGLISH
  function switchToEnglish() {
    setLanguage(true);
  }

  // ANCRE UP
  function scrollToTop() {
    window.scrollTo({
      top: 0,
    });
  }

  // RENDER
  return (
    <article className="lessonCard">
      {/* TITLE & LANGUAGE */}
      <div className="headBox">
        <span
          href={data.url}
          target="_blank"
          rel="noreferrer"
          className="titleBoxy"
        >
          {language === false && (
            <h3 className="lessonTitle">{data.nom.toUpperCase()}</h3>
          )}
          {language === true && (
            <h3 className="lessonTitle">{data.name.toUpperCase()}</h3>
          )}
        </span>

        <div className="languageBox">
          <button
            className="switchLanguageButton"
            aria-label="switch to French"
            onClick={switchToFrench}
          >
            {data.langue}
          </button>
          <button
            className="switchLanguageButton"
            aria-label="switch to English"
            onClick={switchToEnglish}
          >
            {data.language}
          </button>
        </div>
      </div>

      {/* CONTENT */}
      <div className="midBox">
        {/* FRENCH */}
        {language === false && <p>{data.description}</p>}

        {/* ENGLISH */}
        {language === true && <p>{data.ukDescription}</p>}
      </div>

      {/* DURATION & PRICE */}
      <div className="footBox">
        {/* FRENCH */}
        {language === false && (
          <h4 className="duration">{data.durée.toUpperCase()}</h4>
        )}
        {language === false && (
          <h4 className="price">{data.prix.toUpperCase()}</h4>
        )}

        {/* ENGLISH */}
        {language === true && (
          <h4 className="duration">{data.duration.toUpperCase()}</h4>
        )}
        {language === true && (
          <h4 className="price">{data.price.toUpperCase()}</h4>
        )}
      </div>

      {/* RESERVATION BUTTON */}
      <Link
        onClick={scrollToTop}
        to={`/ShowroomLesson/${data.url}`}
        className="reservationButton"
        aria-label="class reservation"
      >
        {language === false && (
          <p className="reservationButtonText">{data.nom.toUpperCase()}</p>
        )}
        {language === true && (
          <p className="reservationButtonText">{data.name.toUpperCase()}</p>
        )}
      </Link>
    </article>
  );
}

export default LessonCard;
