// PageTracker.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      console.log(`Tracking page view: ${location.pathname + location.search}`);
      window.gtag('config', 'G-935LW1KG10', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
};

export default PageTracker;
